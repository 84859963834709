// src/pages/Home.js

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';
import Confetti from 'react-confetti';
import { useWindowSize } from 'react-use';
import { useForm } from 'react-hook-form';

function Home() {
  const { t, i18n } = useTranslation();
  const [isTourActive, setIsTourActive] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [showConfetti, setShowConfetti] = useState(false);
  const { width, height } = useWindowSize();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const isBot = /bot|crawl|spider/i.test(navigator.userAgent);

  const onSubmit = async (data) => {
    try {
      const response = await fetch('/api/email/send', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });
      const result = await response.json();
      if (result.success) {
        alert('Email sent successfully!');
        reset();
      } else {
        alert('Failed to send email. Please try again later.');
      }
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Failed to send email. Please check your network connection.');
    }
  };

  const steps = [
    {
      id: 'step1',
      title: t('process.step1Title'),
      description: t('process.step1Desc'),
      extraContext: t('process.step1Extra'),
    },
    {
      id: 'step2',
      title: t('process.step2Title'),
      description: t('process.step2Desc'),
      extraContext: t('process.step2Extra'),
    },
    {
      id: 'step3',
      title: t('process.step3Title'),
      description: t('process.step3Desc'),
      extraContext: t('process.step3Extra'),
    },
    {
      id: 'step4',
      title: t('process.step4Title'),
      description: t('process.step4Desc'),
      extraContext: t('process.step4Extra'),
    },
  ];

  const startTour = () => {
    setIsTourActive(true);
    setCurrentStep(0);
  };

  useEffect(() => {
    let timer;
    if (isTourActive && currentStep < steps.length) {
      timer = setTimeout(() => {
        if (currentStep < steps.length - 1) {
          setCurrentStep((prev) => prev + 1);
        } else {
          setIsTourActive(false);
          setShowConfetti(true);
          setTimeout(() => {
            setShowConfetti(false);
          }, 2500);
        }
      }, 2500);
    }
    return () => clearTimeout(timer);
  }, [isTourActive, currentStep]);

  const stepVariants = {
    enter: (direction) => ({
      x: direction === 0 ? 0 : direction > 0 ? '100%' : '-100%',
      y: 0,
      scale: direction === 0 ? 0.5 : 1,
      opacity: 0,
    }),
    center: {
      x: 0,
      y: 0,
      scale: 1,
      opacity: 1,
      transition: { type: 'spring', stiffness: 500, damping: 30 },
    },
    exit: (direction) => ({
      x: direction < 0 ? '100%' : '-100%',
      y: 0,
      scale: 0.8,
      opacity: 0,
      transition: { type: 'spring', stiffness: 500, damping: 30 },
    }),
  };

  const overlayVariants = {
    initial: { opacity: 0 },
    animate: { opacity: 0.75 },
    exit: { opacity: 0 },
  };

  const direction = 1;

  return (
    <>
      {isBot && (
        <div style={{ display: 'none' }}>
          {t('seo.keywords')}
        </div>
      )}

      {/* Hero Section */}
      <section className="hero-background text-white py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <motion.h1
            className="hero-title font-bold"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            {t('hero.welcome')}
          </motion.h1>
          <motion.p
            className="mt-6 text-lg"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 0.5 }}
          >
            {t('hero.description')}
          </motion.p>
        </div>
      </section>

      {/* Services Section */}
      <section className="py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-gray-900 text-center">{t('services.title')}</h2>
          <p className="mt-6 text-lg text-gray-600 text-center">{t('services.description')}</p>
          <div className="mt-10 grid grid-cols-1 md:grid-cols-2 gap-8">
            <motion.div className="text-center" whileHover={{ scale: 1.02 }}>
              <h3 className="service-title">{t('services.quickscanTitle')}</h3>
              <p className="service-description">{t('services.quickscanDesc')}</p>
            </motion.div>
            <motion.div className="text-center" whileHover={{ scale: 1.02 }}>
              <h3 className="service-title">{t('services.implementationTitle')}</h3>
              <p className="service-description">{t('services.implementationDesc')}</p>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Process Section */}
      <section className="py-16 bg-gray-100">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-gray-900 text-center">{t('process.title')}</h2>
          <div className="text-center mt-6">
            <button
              onClick={startTour}
              className="px-6 py-3 bg-red-700 text-white font-bold rounded-lg shadow hover:bg-red-800 transition duration-300"
            >
              {t('process.startTour')}
            </button>
          </div>
          <div className="mt-10 grid grid-cols-1 md:grid-cols-4 gap-8">
          {
            steps.map((step, index) => (
              <div key={index} className="text-center">
                <div className="step-number mx-auto">
                  <span className="text-2xl font-bold">{index + 1}</span>
                </div>
                <h3 className="mt-4 text-xl font-bold text-gray-900">{step.title}</h3> {/* Updated to <h3> */}
                <p className="mt-2 text-gray-600">{step.description}</p>
              </div>
            ))};
          </div>
        </div>

        {/* Tour Overlay */}
        <AnimatePresence mode="wait">
          {isTourActive && (
            <>
              <motion.div
                className="fixed inset-0 bg-black z-40"
                variants={overlayVariants}
                initial="initial"
                animate="animate"
                exit="exit"
              ></motion.div>
              <motion.div
                key={currentStep}
                className="fixed inset-0 flex items-center justify-center z-50"
                custom={direction}
                variants={stepVariants}
                initial="enter"
                animate="center"
                exit="exit"
              >
                <div className="bg-white rounded-lg shadow-lg p-8 max-w-lg mx-auto text-center">
                  <h2 className="text-2xl font-bold text-gray-900">{steps[currentStep].title}</h2>
                  <p className="mt-4 text-gray-600">{steps[currentStep].extraContext}</p>
                </div>
              </motion.div>
            </>
          )}
        </AnimatePresence>

        {showConfetti && (
          <>
            <Confetti width={width} height={height} />
            <div className="fixed inset-0 flex items-center justify-center z-50">
              <motion.div
                className="bg-white rounded-lg shadow-lg p-8 max-w-lg mx-auto text-center"
                initial={{ scale: 0.8, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.8, opacity: 0 }}
              >
                <h2 className="text-2xl font-bold text-gray-900">
                  {t('process.congratsMessage')}
                </h2>
              </motion.div>
            </div>
          </>
        )}
      </section>

      {/* Contact Form Section */}
      <section id="contact" className="py-16">
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-gray-900 text-center">{t('contact.title')}</h2>
          <p className="mt-6 text-lg text-gray-600 text-center">{t('contact.description')}</p>
          <form onSubmit={handleSubmit(onSubmit)} className="mt-8 space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                  {t('contact.name')}
                </label>
                <input
                  id="name"
                  name="name"
                  {...register('name', { required: true })}
                  className="contact-input mt-1 block w-full"
                />
                {errors.name && <span className="text-red-500 text-sm">{t('contact.errors.name')}</span>}
              </div>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  {t('contact.email')}
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  {...register('email', { required: true })}
                  className="contact-input mt-1 block w-full"
                />
                {errors.email && <span className="text-red-500 text-sm">{t('contact.errors.email')}</span>}
              </div>
            </div>
            <div>
              <label htmlFor="message" className="block text-sm font-medium text-gray-700">
                {t('contact.message')}
              </label>
              <textarea
                id="message"
                name="message"
                rows="5"
                {...register('message', { required: true })}
                className="contact-input mt-1 block w-full"
              ></textarea>
              {errors.message && <span className="text-red-500 text-sm">{t('contact.errors.message')}</span>}
            </div>
            <div className="text-center">
              <button
                type="submit"
                className="contact-button bg-red-700 hover:bg-red-800 text-white"
              >
                {t('contact.submit')}
              </button>
            </div>
          </form>
        </div>
      </section>

      {/* Call to Action Section */}
      <section className="py-24 bg-red-700 text-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h3 className="text-3xl font-bold">{t('cta.title')}</h3>
          <p className="mt-6 text-lg">{t('cta.description')}</p>
          <motion.button
            className="cta-button mt-8 bg-gray-900 hover:bg-gray-800 text-white"
            whileHover={{ scale: 1.05 }}
            onClick={() => {
              document.getElementById('contact').scrollIntoView({ behavior: 'smooth' });
            }}
          >
            {t('cta.contactUs')}
          </motion.button>
        </div>
      </section>
    </>
  );
}

export default Home;
